import { data } from "jquery";
import { postRequest } from "../config/AxiosConfig";
import API_URLS from "../config/BackendConfig";
import { getAuthToken } from "../utils/tokenUtils"

export const RetriveServicecategoryService = () => {
    try {
        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "retrive/service-category",
            headers: { 'Authorization': `Bearer ${token}` }
        })
        return response;
    } catch (error) {

    }
}
export const RetriveServiceReportService = () => {
    try {
        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "service/report",
            headers: { 'Authorization': `Bearer ${token}` }
        })
        return response;
    } catch (error) {

    }
}
export const RetriveActiveServiceReportService = async() => {
    try {
        const token = getAuthToken();
        const response =await postRequest({
            url: API_URLS.ADMIN + "service/assigned/report",
            headers: { 'Authorization': `Bearer ${token}`,
        data:{'total_data':14} }
        })
        // console.log(response);
        return response;
    } catch (error) {

    }
}
export const RetriveServiceEditService = ({ serviceId }) => {
    try {
        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "service/edit",
            data: { "service_id": serviceId },
            headers: { 'Authorization': `Bearer ${token}` }
        })
        return response;
    } catch (error) {

    }
}

export const addApiService = async ({ categoryId, serviceName,
    postUrl, introduction,
    requestResponse,
    failedResponse,
    fees,
    adjustment_type,
    successResponse

}) => {
    try {
        const payload = {
            "service_category_id": categoryId,
            "service_name": serviceName,
            "end_point": postUrl,
            "introduction": introduction,
            "request": requestResponse,
            "failed_response": failedResponse,
            "activation_fee": fees,
            "agreement_required": true,
            "adjustment_type": adjustment_type,
            "response": successResponse
        }
        console.log(payload)
        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "create/service",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        })
        return response;
    } catch (error) {

    }
}

export const updateApiService = async ({ serviceId, categoryId, serviceName,
    postUrl, introduction,
    requestResponse,
    failedResponse,
    fees,
    adjustment_type,
    successResponse

}) => {
    try {
        const payload = {
            "service_id": serviceId,
            "service_category_id": categoryId,
            "service_name": serviceName,
            "end_point": postUrl,
            "introduction": introduction,
            "request": requestResponse,
            "failed_response": failedResponse,
            "activation_fee": fees,
            "agreement_required": true,
            "adjustment_type": adjustment_type,
            "response": successResponse
        }

        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "service/update",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        })
        return response;
    } catch (error) {
        return error
    }
}


export const asignService = async ({
    formData
}) => {
    try {
        const payload = {
            "service_id": formData.serviceId,
            "user_id": formData.userId,
            "package_id": formData.packageId,
            "remarks": formData.remarks,
            "domain": formData.postUrl,
            "sales_employee_id": formData.staffId
        }
        // console.log(formData)
        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "service/assign",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        })
        return response;
    } catch (error) {
        return error;
    }
}

export const toggleService = (serviceid) => {
    try {
        const payload = {
            "service_id": serviceid
        }
        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "service/toggle-status",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        })
        return response
    } catch (error) {
        return error
    }
}


export const updateAssignedReportStatus = ({ userId, assignMentId, statusType }) => {
    try {
        const payload = {
            "user_id": userId,
            "assignment_id": assignMentId,
            "status": statusType
        }
  
        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "service/assigned/report",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        })
        return response
    } catch (error) {
        return error
    }
}