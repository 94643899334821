import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Header from "./pages/DashBoardMenu/HeaderComponent/Header";
import SideBarMenuDashboard from "./pages/SideBarMenu/SideBarMenuDashbaord";
import DashboardMainContent from "./pages/DashBoardMenu/DashBoardMainContent";
import ApiAccount from "./pages/APIAccountPage/ApiAccount/ApiAccount";
import CommissionMaster from "./pages/APIAccountPage/CommissionMaster/CommissionMaster";
import Wallet_balance_management from "./pages/APIAccountPage/WalletBalManagement/Wallet_balance_management";
import IdfcVanConfig from "./pages/ManageCollectionsMenu/IdfcVanConfig/IdfcVanConfig";
import IdfcVanReport from "./pages/ManageCollectionsMenu/IdfcVanReport/IdfcVanReport";
import PayoutReport from "./pages/ReportMenu/PayoutReport";
import ApiUserKyc from "./pages/APIAccountPage/UploadKYC/ApiUserKyc";
import ApiApproveKyc from "./pages/APIAccountPage/ApproveKYC/ApiApproveKyc";
import ActionForApprove from "./pages/APIAccountPage/ApproveKYC/ActionForApprove";
import ActiveServiceReport from "./pages/ServicesMenu/ActiveServiceReport/ActiveServiceReport";
import AddService from "./pages/ServicesMenu/AddService/AddService";
import ActivateService from "./pages/ServicesMenu/ActivateService/ActivateService";
import AdminLogin from "./pages/LoginPage/AdminLogin.jsx";
import ManagePermission from "./pages/APIAccountPage/ManagePermission/ManagePermission.jsx";
import PrivateRoute from "./pages/Components/PrivateRoute.js";
import CustomerReport from "./pages/ReportMenu/CustomerReport.jsx";
import ApiKeyReport from "./pages/ReportMenu/ApiKeyReport.jsx"
import NotFound from "./pages/ReportMenu/NotFound.jsx";
import FundManagementReport from "./pages/APIAccountPage/WalletBalManagement/FundManagementReport.jsx";
import PartnerShip from "./pages/PartnerShipMenu/PartnerShip.jsx";
import FundApprove from'./pages/APIAccountPage/ApproveFund/ApproveFund.jsx';
import ProviderReport from "./pages/ManageCollectionsMenu/ProvidersReport/ProvidersReport.jsx";
import LeadReport from "./pages/PartnerShipMenu/LeadReport/LeadReport.jsx";
import MasterTraining from './pages/MasterTraining/MasterTraining.jsx';

const AppContent = () => {
  const location = useLocation();

  const isLoginPage = location.pathname === "/";
  const isLoginPage2= location.pathname === "*";

  return (
    <div className={`min-h-screen flex flex-col ${isLoginPage2 || isLoginPage ? "md:w-full" : "lg:flex-row"}`}>
      { !isLoginPage && <Header className="" />}
      { !isLoginPage && <SideBarMenuDashboard className="" />}{" "}
      <ToastContainer />
      <div
        id=""
        className={`bg-gradient-to-r from-blue-800 via-teal-700 to-purple-500 rounded-md shadow-lg flex-grow ${
          isLoginPage ? "md:w-full" : "lg:w-[calc(100%-250px)]"
        }`}
      >
        <Routes>
          <Route path="/" element={<AdminLogin />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/admin-dashboard" element={<PrivateRoute><DashboardMainContent /></PrivateRoute>} />
          <Route path="/ActivateService" element={<PrivateRoute><ActivateService /></PrivateRoute>} />
          <Route path="/ActiveServiceReport" element={<PrivateRoute><ActiveServiceReport /></PrivateRoute>} />
          <Route path="/AddService" element={<PrivateRoute><AddService /></PrivateRoute>} />
          <Route path="/ActionForApprove" element={<PrivateRoute><ActionForApprove /></PrivateRoute>} />
          <Route path="/ApiApproveKyc" element={<PrivateRoute><ApiApproveKyc /></PrivateRoute>} />
          <Route path="/ApiUserKyc" element={<PrivateRoute><ApiUserKyc /></PrivateRoute>} />
          <Route path="/PayoutReport" element={<PrivateRoute><PayoutReport /></PrivateRoute>} />
          <Route path="/CustomerReport" element={<PrivateRoute><CustomerReport /></PrivateRoute>} />
          <Route path="/ApiKeyReport" element={<PrivateRoute><ApiKeyReport /></PrivateRoute>} />
          <Route path="/IdfcVanConfig" element={<PrivateRoute><IdfcVanConfig /></PrivateRoute>} />
          <Route path="/IdfcVanReport" element={<PrivateRoute><IdfcVanReport /></PrivateRoute>} />
          <Route path="/ProvidersReport" element={<PrivateRoute><ProviderReport /></PrivateRoute>} />
          <Route path="/ApiAccount" element={<PrivateRoute><ApiAccount /></PrivateRoute>} />
          <Route path="/PartnerShip" element={<PrivateRoute><PartnerShip /></PrivateRoute>} />
          <Route path="/MasterTraining" element={<PrivateRoute><MasterTraining /></PrivateRoute>} />
          <Route path="/LeadReport" element={<PrivateRoute><LeadReport /></PrivateRoute>} />
          <Route path="/Wallet_balance_management" element={<PrivateRoute><Wallet_balance_management /></PrivateRoute>} />
          <Route path="/wallet-transaction-report" element={<PrivateRoute><FundManagementReport /></PrivateRoute>} />
          <Route path="/CommissionMaster" element={<PrivateRoute><CommissionMaster /></PrivateRoute>} />
          <Route path="/ManagePermission" element={<PrivateRoute><ManagePermission /></PrivateRoute>} />
          <Route path="/FundApprove" element={<PrivateRoute><FundApprove /></PrivateRoute>} />
        </Routes>
      </div>
    </div>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
