import React, { useState, useEffect } from 'react';
import DivTag from '../../Components/DivComponent/DivTag';
import Button from '../../Components/ButtonComponent/Button';
import FormValidation from '../../Components/FormValidation/FormValidation';
import InputWithLabel from '../../Components/InputComponent/InputWithLabel';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';
import ReportHeading from '../../Components/ReportHeadings/ReportHeadings';
import Option from '../../Components/OptionComponent/Option';

import Pagination from '../../Components/Pagination/Pagination';
import FormTag from '../../Components/FormComponent/FormTag';
import { motion } from 'framer-motion';

import { ApprovedDocumentService, DocumentPreviewService, GetUploadedDocumentIdService, RejectDocumentService } from '../../../services/apiService';
import PopMenu from '../../../components/Dialog/PopMenu';
import { ToastContainer } from 'react-toastify';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';
import Seo from '../../../components/SeoComponent/Seo';

const ApiApproveKyc = () => {

    const initialState = {
        from_date: '',
        to_date: '',
        status: ''
    };

    const validate = (values) => {
        let errors = {};

        // Add validation logic here
        if (!values.from_date) {
            errors.from_date = 'From Date is required';
        }
        if (!values.to_date) {
            errors.to_date = 'To Date is required';
        }

        if (!values.status || values.status === '') {
            errors.status = 'Status is required';
        }
        return errors;
    };

    const { values, errors, handleChange, handleBlur } = FormValidation(
        initialState,
        validate
    );

    // Define default table data
    const [defaultTableData, setDefaultTableData] = useState([
    ]);
    const [isLoading, setIsLoading] = useState(false)


    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5); // Change this number as per your requirement

    // Calculate total number of pages
    const totalPages = Math.ceil(defaultTableData.length / itemsPerPage);

    // Logic to handle pagination
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Get current items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = defaultTableData.slice(indexOfFirstItem, indexOfLastItem);
    const [uploadedDocument, setUploadedDocument] = useState([]);

    const [searchText, setSearchText] = useState('');
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    const [remarks, setRemarks] = useState('');

    const [docId, setDocId] = useState('');
    async function UplodedDocumentLists() {
        try {
            setIsLoading(true);
            const response = await GetUploadedDocumentIdService({ userId: searchText.toUpperCase() });
            console.log(response)
            setUploadedDocument(response.data);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            return
        }
    }
    const handleOpenKycApprove = (docId) => {
        setDocId(docId)
        setIsPreviewOpen(true)

    }

    async function handleApproveDocument() {
        try {
            setIsLoading(true);
            const response = await ApprovedDocumentService(
                {
                    userId: searchText,
                    docid: docId,
                    remarks: remarks
                }
            );
            if (response.status === 403) {
                setRemarks('');
                setIsLoading(false);
                showErrorToast(response.data.message);
                setIsPreviewOpen(false)
                return
            }
            if (response.status === 'success') {
                setRemarks('');
                showSuccessToast('Document Approved Successfully');
                await UplodedDocumentLists();
                setIsLoading(false)
                setIsPreviewOpen(false);
                return;
            }
            setRemarks('');
            setIsPreviewOpen(false);
            showErrorToast(response.message);
            setIsLoading(false)
            return;
        } catch (error) {
            setRemarks('');
            setIsPreviewOpen(false);
            showErrorToast('Failed to approved ');
            setIsLoading(false)
            return
        }
    }
    async function handleRejectDocument() {
        try {
            setIsLoading(true)
            const response = await RejectDocumentService(
                {
                    userId: searchText,
                    docid: docId,
                    remarks: remarks
                }
            );

            if (response.status === 'success') {
                setRemarks('');
                await UplodedDocumentLists();

                showSuccessToast('Document Approved Successfully');
                setIsLoading(false)
                setIsPreviewOpen(false);
                return;
            }
            setIsPreviewOpen(false);
            setIsLoading(false)
            showErrorToast(response.message);
            return;
        } catch (error) {
            setIsLoading(false)
            setIsPreviewOpen(false);
            showErrorToast('Failed to approved ');
            return
        }
    }
    async function getImagePreveiew(docName) {
        const response = await DocumentPreviewService(docName);
       
        return response?.dataUri;

    }
    return (

        <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2 }}
        >
            <Seo />

            <DivTag id="" className="border-2 border-solid border-blue-800 rounded-xl border-t-0 mt-12 bg-white">

                <ReportHeading name="Approve KYC" />
                <DivTag className="flex justify-center items-center">


                    <PopMenu visible={isPreviewOpen}
                        onClose={() => setIsPreviewOpen(false)}
                        className={"h-[60%] top-[10%] w-1/2"}
                        title={"Kyc Status"}
                    >
                        <h1 className='text-center my-1 text-xl font-semibold text-sky-400'>Approved Kyc Document </h1>

                        <p className='text-center my-1 text-xl font-semibold text-sky-400'>Select userId : {searchText}</p>
                        <p className='text-center my-1 text-xl font-semibold text-sky-400'>Document  id : {docId}</p>
                        <div className='mx-[30%] my-2 '>
                            <h4 className='text-xl text-black font-bold'>Remarks <span className='text-red-500 text-xl font-semibold'>*</span></h4>

                            <textarea
                                className='border-2 my-2 border-sky-400 shadow-md outline-none p-2 text-xl
                             bg-white text-black'
                                name="remarks" rows={4} cols={25} id="" value={remarks}
                                onChange={(e) => setRemarks(e.target.value)} placeholder='write your remarks'></textarea>

                        </div>
                        <div className='flex w-full px-10 ml-10 justify-around items-center'>
                            <button className={isLoading ? "py-2 -ml-20 px-2 w-1/3 rounded bg-slate-500 cursor-not-allowed" : 'py-2 -ml-20 px-2 w-1/3 rounded bg-green-500 text-white cursor-pointer hover:scale-105 transition-all'} onClick={handleApproveDocument} disabled={isLoading}>Approved </button>
                            <button className={isLoading ? "cursor-not-allowed py-2 -ml-20 px-2 w-1/3 rounded bg-slate-500" : 'py-2 -ml-20 px-2 w-1/3 rounded bg-red-500 text-white cursor-pointer hover:scale-105 transition-all'} onClick={handleRejectDocument} disabled={isLoading}>Reject </button>
                        </div>

                    </PopMenu>


                    <FormTag
                        method=""
                        autoComplete="off" >

                        <DivTag className="flex flex-wrap p-3 mt-2">
                            <div className='flex flex-col items-start -mt-2'>
                                <label htmlFor="" className='text-slate-600 font-bold'>Search User</label>
                                <input type='text' onChange={(e) => setSearchText(e.target.value)} value={searchText.toUpperCase()}
                                    placeholder='Search..'
                                    className='border shadow-md h-9 rounded-sm text-black outline-none px-2'
                                />
                            </div>

                            <SelectWithLabelOption
                                DivclassName="w-full md:w-1/4 px-3 md:mb-0"
                                label="Status"
                                id="status"
                                name="status"
                                value={values.status}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.status ? 'border border-red-500' : 'border border-blue-600'}
                                error={errors.status}
                                required={true}
                            >
                                <Option value="">Select Status</Option>
                                <Option value="Varified">Verified</Option>
                                <Option value="Reject">Reject</Option>
                                <Option value="Pending">Pending</Option>
                            </SelectWithLabelOption>


                            <InputWithLabel
                                DivclassName="w-full md:w-1/4 px-3 md:mb-0"
                                label="From Date"
                                type="date"
                                name="from_date"
                                id="from_date"
                                value={values.from_date}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.from_date ? 'border border-red-500' : 'border border-blue-600'}
                                error={errors.from_date}
                                required={true}
                            />


                            <InputWithLabel
                                DivclassName="w-full md:w-1/4 px-3 md:mb-0"
                                label="To Date"
                                type="date"
                                name="to_date"
                                id="to_date"
                                value={values.to_date}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.to_date ? 'border border-red-500' : 'border border-blue-600'}
                                error={errors.to_date}
                                required={true}
                            />





                        </DivTag>
                    </FormTag>

                </DivTag>

                <DivTag className="flex justify-center">
                    <Button
                        type="submit"
                        className={isLoading ? "cursor-not-allowed text-white bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900 via-teal-400 to-purple-900 font-semibold p-0 rounded-md px-2 py-1 text-lg hover:bg-red-300" : "text-center text-white bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900 via-teal-400 to-purple-900 font-semibold p-0 rounded-md px-2 py-1 text-lg hover:bg-red-300"}
                        disabled={isLoading}
                        onClick={UplodedDocumentLists}
                    >
                        Submit
                    </Button>
                </DivTag>



                <HeadingTag className="text-2xl text-blue-800 font-bold bg-white text-center mt-2 mb-2" text="Report" />

                <DivTag className="d-flex justify-center mt-5">
                    <table className="min-w-full table-auto border-collapse">
                        <thead className="text-xs bg-gray-100">
                            <tr className='bg-blue-600 text-white'>
                                <th className="px-4 py-2 text-center">S No.</th>
                                <th className="px-4 py-2 text-center">Document</th>
                                <th className="px-4 py-2 text-center">Document Preview</th>
                                <th className="px-4 py-2 text-center">Status</th>
                                <th className="px-4 py-2 text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                uploadedDocument.length > 0 ?
                                    uploadedDocument.map((item, index) => (
                                        <tr key={index} className="border-t">
                                            <td className="px-4 py-2 text-center">{index + 1}</td>
                                            <td className="px-4 py-2 text-center">{item?.document.name}</td>
                                            <td className="px-4 py-2 text-center">
                                                <img src={getImagePreveiew(item?.document_path)} alt={item?.document.name} />
                                            </td>
                                            <td className="px-4 py-2 text-center font-bold">{item?.status}</td>
                                            <td className="px-4 py-2 text-center">
                                                {
                                                    item?.status === "approved" ?
                                                        <p className='text-xl text-green-500'>Document Approved</p>

                                                        : item?.status === "reject"
                                                            ?
                                                            <p className='text-xl text-red-500'>Document Reject</p>
                                                            :
                                                            item?.status === "approved" || item?.status !== "reject" || item?.status === "pending"
                                                                ?
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        className="text-white bg-red-500 rounded-sm p-1"
                                                                        onClick={() => handleOpenKycApprove(item.document.id)
                                                                        }
                                                                    >
                                                                        Action
                                                                    </button>

                                                                </div>
                                                                : ""
                                                }




                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan="5" className="px-4 py-2 text-center text-black text-xl">Data Not Found</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </DivTag>

                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
                <ToastContainer />
            </DivTag>
        </motion.div>

    );

};

export default ApiApproveKyc;